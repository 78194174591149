import { Button, Modal, Typography } from "antd";
const ConfirmationModal = ({
  isOpen,
  onOk,
  onCancel,
  ConfirmationHeading,
  ConfirmationParagraph,
  logout = "false",
}) => {
  return (
    <>
      <Modal
        title={ConfirmationHeading}
        centered
        open={isOpen}
        width={300}
        footer={[
          <Button key="back" type={logout === true ? "primary": ""} onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="submit" type={logout === true ? "": "primary"} onClick={onOk}>
            OK
          </Button>,
          
        ]}

      >
        <Typography className="text-sm mb-4">
          {ConfirmationParagraph}
        </Typography>
      </Modal>
    </>
  );
};
export default ConfirmationModal;