import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Typography,
  Image,
  Upload,
  Input,
  Form,
} from "antd";
import { EditOutlined, UnlockOutlined, UserOutlined } from "@ant-design/icons";
import ChangePassword from "../../Modal/ChangePassword";
import { Link, useNavigate } from "react-router-dom";
import { getProfileApi, updateProfileApi } from "../../service/auth.service";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import storage from "../../Utils/storageUtils";
import { USER_DATA } from "../../Utils/constant";

const AccountSettings = () => {
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [FileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [pData, setPData] = useState({});
  const [isImageDeleted, setIsImageDeleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const [prevData, setPrevData] = useState({});
  const [prevFile, setPrevFile] = useState([]);

  const handleChange = async ({ fileList }) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (fileList.length) {
      const file = fileList[0].originFileObj;
      const maxSize = 5 * 1024 * 1024; // 5 MB size limit

      if (!allowedTypes.includes(file.type)) {
        toast.error("File type is not image");
        return;
      }
      if (file.size > maxSize) {
        toast.error("File size exceeds 5 MB limit!");
        return;
      }
      setFileList(fileList);
    } else {
      let fileObj = FileList;
      try {
        setFileList(fileList);
      } catch (error) {
        toast.error(error?.response?.data?.message || error?.message);
        setFileList(fileObj);
      }
    }
  };
  const handlePreview = async (file) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewOpen(true);
  };

  const handleEdit = async () => {
    try {
      if (!isEditable) {
        setIsEditable(!isEditable);
      } else {
        let value = await form.validateFields();
        let phoneNumber;
        if (value?.phoneWithCountry) {
          phoneNumber = parsePhoneNumber(value?.phoneWithCountry);
        }
        let countryCode = phoneNumber ? phoneNumber?.countryCallingCode : "";
        let formData = new FormData();
        formData.append("first_name", value?.first_name);
        formData.append(
          "phone",
          phoneNumber ? phoneNumber?.nationalNumber : ""
        );
        if (FileList[0]?.originFileObj) {
          formData.append("profile_image", FileList[0]?.originFileObj);
        } else if (!FileList[0]?.originFileObj && isImageDeleted) {
          formData.append("profile_image", true);
        }
        formData.append(
          "country_code",
          countryCode
            ? countryCode.includes("+")
              ? countryCode
              : "+" + countryCode
            : ""
        );
        setLoading(true);
        let res = await updateProfileApi(formData);

        if (res?.status === 200) {
          setIsImageDeleted(false);
          getData();
          setPrevData({
            ...res?.data?.data,
            phoneWithCountry: res?.data?.data?.phone,
          });
          let storageData = storage.getItem(USER_DATA);
          storageData.name = res?.data?.data?.first_name;
          storageData.profile_image = res?.data?.data?.profile_image;
          storage.setItem(USER_DATA, storageData);
          toast.success(res?.data?.message);
          setIsEditable(false);
          Navigate("/account-setting");
        } else {
          toast.error(res?.data?.message);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const getData = async () => {
    try {
      let res = await getProfileApi();
      form.setFieldsValue({
        ...res?.data?.data,
        phoneWithCountry: res?.data?.data?.phone,
      });

      setPData(res?.data?.data);
      setPrevData({
        ...res?.data?.data,
        phoneWithCountry: res?.data?.data?.phone,
      });

      if (res?.data?.data?.profile_image) {
        setFileList([
          {
            url: res?.data?.data?.profile_image,
          },
        ]);
        setPrevFile([
          {
            url: res?.data?.data?.profile_image,
          },
        ]);
      } else {
        setPrevFile([]);
        setFileList([]);
      }
    } catch (error) {}
  };
  const validatePhoneNumber = (rule, value) => {
    try {
      if (value) {
        const phoneNumber = parsePhoneNumber(value);
        if (phoneNumber && isValidPhoneNumber(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject("Please enter a valid phone number");
        }
      } else {
        return Promise.resolve();
      }
    } catch (error) {
      return Promise.reject("Please enter a valid phone number");
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Card>
        <div className="flex justify-between items-center flex-wrap mb-4 gap-3">
          <Typography className="text-xl font-semibold" align={"left"}>
            {isEditable ? "Edit profile" : "Profile"}
          </Typography>
        </div>
        <Divider />
        <div className="min-h-[calc(100vh_-_305px)]">
          <div className="mb-4 h-[102px]">
            <Upload
              action=""
              listType="picture-card"
              fileList={FileList}
              disabled={!isEditable}
              onPreview={handlePreview}
              onChange={handleChange}
              onRemove={() => setIsImageDeleted(true)}
              beforeUpload={() => false} // Prevent auto-upload
            >
              {FileList.length >= 1 ? null : (
                <>
                  {" "}
                  <button
                    style={{
                      border: 0,
                      background: "none",
                    }}
                    type="button"
                  >
                    <UserOutlined className="text-[36px]" />
                  </button>
                </>
              )}
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{
                  display: "none",
                }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            )}
          </div>
          <Form
            name="Profile"
            layout="vertical"
            form={form}
            style={{
              maxWidth: 600,
              width: "100%",
            }}
            initialValues={{
              remember: true,
              ...pData,
            }}
            autoComplete="off"
          >
            <Form.Item
              label="Admin Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please enter your Admin Name",
                },
                {
                  min: 3,
                  message: "Min 3 char. required",
                },
                {
                  max: 25,
                  message: "Name should not exceed 25 char.",
                },
                {
                  pattern: /^[A-Za-z\s]+$/,
                  message: "Name can only contain letters and spaces",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Admin Name"
                readOnly={!isEditable}
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your Email Address",
                },
                {
                  type: "email",
                  message: "Please enter a valid Email Address",
                },
              ]}
            >
              <Input size="large" placeholder="Email Address" readOnly={true} />
            </Form.Item>
            <Form.Item
              label="Phone number"
              name="phoneWithCountry"
              rules={[{ validator: validatePhoneNumber }]}
            >
              <PhoneInput
                size="large"
                className="border border-[#d9d9d9] rounded-lg	px-[11px] h-[40px]"
                placeholder="Phone number"
                country="US"
                defaultCountry="US"
                readOnly={!isEditable}
              />
            </Form.Item>

            {isEditable ? (
              ""
            ) : (
              <Form.Item label="Referral link" name="Referral link">
                <Link className="link" to={pData?.referral_token}>
                  {pData?.referral_token}
                </Link>
              </Form.Item>
            )}
          </Form>
        </div>
        <Divider />
        <div className="flex justify-end flex-wrap gap-3">
          {isEditable ? (
            <Button
              onClick={() => {
                form.setFieldsValue(prevData);

                setFileList(prevFile);
                setIsEditable(false);
              }}
            >
              Cancel
            </Button>
          ) : (
            <Button
              type="text"
              icon={<UnlockOutlined />}
              size={14}
              onClick={() => setIsChangePasswordModalOpen(true)}
              className=" cursor-pointer"
            >
              Change Password
            </Button>
          )}

          <Button
            type="primary"
            success
            onClick={handleEdit}
            icon={isEditable ? "" : <EditOutlined />}
            loading={loading}
          >
            {isEditable ? "Save" : "Edit Profile"}
          </Button>
        </div>
      </Card>
      <ChangePassword
        isOpen={isChangePasswordModalOpen}
        setIsOpen={setIsChangePasswordModalOpen}
      />
    </>
  );
};
export default AccountSettings;
