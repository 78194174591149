import moment from "moment-timezone";
import { unparse } from "papaparse";

export const getCountryName = (countryList, short_code) => {
  if (!countryList || !countryList.length || !short_code) return "N/A";
  let country_name = countryList.find((data) => data.short_name === short_code);
  if (country_name) {
    return country_name.name;
  }
  return short_code;
};

export const formatDate = (date, type = "d") => {
  if (!date) return "-";
  if (type === "d") return moment(date).format("DD/MM/YYYY");
  if (type === "dt")
    return (
      moment(date)
        .tz("America/New_York")
        .utcOffset(-5)
        .format("MM/DD/YYYY hh:mm A") + " (EST)"
    );
};

export const spStatus = {
  pending: "Invitation pending",
  active: "Active",
  inactive: "Inactive",
};
export const generateUrl = (name, path, id) => {
  return `${path}/${id}/${name}`;
};

export const getDealDate = (currentStage, allStages) => {
  return formatDate(
    allStages?.find((data) => data?.slug === currentStage)?.date
  );
};
export const removeUnderScore = (str) => {
  if (!str) return "";
  return str
    .replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) //
    .join(" ");
};

export const exportToCsv = (data, filename = "data.csv") => {
  const csv = unparse(data); // Converts data into CSV format
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  if (navigator.msSaveBlob) {
    // For IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
