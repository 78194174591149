import Axios from "./axios";

export const getSpListingApi = (filter) => {
  return Axios.get(
    `admin/sp-list?page=${filter?.page ?? ""}&page_size=${
      filter?.limit ?? ""
    }&sort_field=${filter?.sort_field ?? ""}&sort_direction=${
      filter?.sort_direction ?? ""
    }&status=${filter?.status ?? ""}&search=${filter?.search ?? ""}`
  );
};

export const addServiceProviderApi = (data) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return Axios.post(`admin/sp-create`, data, config);
};
export const editServiceProviderApi = (id, data) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return Axios.put(`admin/sp-edit/${id}`, data, config);
};

export const deleteServiceProviderApi = (id) => {
  return Axios.delete(`admin/sp-delete/${id}`);
};
export const updateSpStatusApi = (id, data) => {
  return Axios.put(`admin/sp-update-status/${id}`, data);
};
export const serviceProviderDetailApi = (id) => {
  return Axios.get(`admin/sp-detail/${id}`);
};

export const resetPwdLinkAPi = (id) => {
  return Axios.post(`admin/sp-resend-password/${id}`);
};
export const spOpportunityListApi = (id) => {
  return Axios.get(
    `admin/service-provider-associated-opportunity/${id}&page_size=1000`
  );
};

