import React, { useEffect, useState } from "react";
import { AutoComplete, Table } from "antd";
import { getOpportunityListing } from "../../service/opportunative.service";
import {
  getUpdateDataApi,
  updateStatusApi,
} from "../../service/common.service";
import { toast } from "react-toastify";

let timer = null;
const StatusUpdate = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [leadListing, setLeadListing] = useState({
    data: [],
    column_keys: [],
  });
  const [id, setId] = useState("");
  const getOppData = async () => {
    try {
      let res = await getOpportunityListing({
        limit: 100000,
        search: search,
      });
      if (res?.status === 200) {
        let apiData = res?.data?.data?.data;

        let modifyData = apiData?.map((d) => {
          return {
            label: d?.opportunity_name,
            value: d?.opportunity_name,
            id: d?.id,
          };
        });
        setData(modifyData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      getOppData();
    }, 300);
  }, [search]);
  const leads = async () => {
    try {
      let res = await getUpdateDataApi(id.id);
      if (res?.status === 200) {
        setLeadListing(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id) {
      leads();
    }
  }, [id]);
  const handleSelect = (value, option) => {
    setId(option);
  };
  const mergeData = (dataD) => {
    return dataD?.map((item) => {
      let { customer_detail_json, ...rest } = item;
      return {
        ...customer_detail_json,
        ...rest,
      };
    });
  };
  const columnsData = (columns) => {
    return columns.map((item) => {
      return {
        title: item,
        dataIndex: item,
        key: item,
      };
    });
  };
  const formatData = (data) => {
    let filterData = data?.filter((item) => !item?.date);
    return filterData?.map((item) => {
      return {
        key: item?.key + " " + item?.value,
        value: item?.key + " " + item?.value,
        slug: item?.slug,
      };
    });
  };
  const onStatusChange = async (stage, val) => {
    try {
      let res = await updateStatusApi(val?.id, {
        stage: stage?.slug,
      });
      if (res?.status === 200) {
        leads();
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      console.log(error);
    }
  };
  return (
    <div className="w-full text-center">
      <h1 className="text-3xl">Status Update</h1>
      <div className="w-full mt-10">
        <AutoComplete
          options={data}
          style={{ width: "300px" }}
          placeholder="Please select"
          onSelect={handleSelect}
          onSearch={(text) => {
            setSearch(text);
          }}
        />
      </div>
      <div className="relative overflow-x-auto">
        <div className="w-full overflow-x-auto overflow-y-hidden">
          <Table
            className="table-auto  min-w-[1140px]"
            columns={[
              {
                title: "Affiliate",
                dataIndex: "user",
                key: "first_name",
                render: (user) => (
                  <p>{user?.first_name + " " + user?.last_name}</p>
                ),
              },

              ...columnsData(leadListing?.column_keys),
              {
                title: "Status and timeline",
                dataIndex: "stage",
                key: "stage",
                render: (_, data) => {
                  let stg = data?.stage_timeline?.find(
                    (val) => val?.slug === data?.stage
                  );
                  return stg ? stg?.key + " " + stg?.value : "-";
                },
              },
              {
                title: "Update status",
                dataIndex: "stage_timeline",
                key: "stage",
                render: (stage_timeline, data) => {
                  return (
                    <p>
                      <AutoComplete
                        options={formatData(stage_timeline)}
                        style={{ width: "300px" }}
                        placeholder="Please select"
                        onSelect={(v, value) => onStatusChange(value, data)}
                      />
                    </p>
                  );
                },
              },
            ]}
            dataSource={mergeData(leadListing?.data)}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default StatusUpdate;
