import { Form, Input, Modal } from "antd";
import { changePasswordApi } from "../../service/auth.service";
import { useState } from "react";
import { toast } from "react-toastify";

const ChangePassword = ({ isOpen, setIsOpen }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const validateConfirmPassword = (_, value) => {
    if (!value || form.getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Passwords do NOT match")
    );
  };
  const onChangePassword = async (value) => {
    try {
      let value = await form.validateFields();
      setLoading(true);
      let res = await changePasswordApi(value);
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        form.resetFields();
        setIsOpen(false);
      } else {
        toast.error(res?.data?.message);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setLoading(false);
    }
  };
  const closePopup =() =>{
    setIsOpen(false)
    form.resetFields();
  }
  return (
    <>
      <Modal
        title="Change Password"
        centered
        open={isOpen}
        onCancel={closePopup}
        onOk={onChangePassword}
        okText="Save"
        confirmLoading={loading}
        cancelText="Cancel"
      >
        <Form
          name="change_password"
          layout="vertical"
          form={form}
          initialValues={{
            remember: true,
          }}
          style={{ marginTop: 24 }}
          onFinish={onChangePassword}
          autoComplete="off"
        >
          <Form.Item
            label="Old Password"
            name="old_password"
            rules={[
              {
                required: true,
                message: "Old password is required",
              },
              {
                min: 10,
                message: "Password should be minimum 10 char long",
              },
              {
                max: 50,
                message: "Password should not be exceed more than 50 char long",
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your New password!",
              },
              {
                min: 10,
                message: "Password should be minimum 10 char long",
              },
              {
                max: 50,
                message: "Password should not be exceed more than 50 char long",
              },
              {
                pattern: /[A-Za-z]/,
                message: "Password must contain at least one letter",
              },
              {
                pattern: /\d/,
                message: "Password must contain at least one number",
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirm_password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please enter your confirm new password!",
              },
              {
                min: 10,
                message: "Password should be minimum 10 char long",
              },
              {
                max: 50,
                message: "Password should not be exceed more than 50 char long",
              },
              { validator: validateConfirmPassword },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ChangePassword;
