import axios from "axios";
import storage from "../Utils/storageUtils";
import { TOKEN_NAME } from "../Utils/constant";

let baseURL = `${process.env.REACT_APP_BASE_PATH}api/v1/`;

const Axios = axios.create({
  baseURL: baseURL,  
  timeout: 20000, 
  headers: {
    "Content-Type": "application/json",
    "x-api-key": "king-credit-582ef09-4f-8b0b-31124e-593e5ca",
  },
});

// function to attach interceptors
const attachInterceptors = (navigate, toast) => {
  Axios.interceptors.request.use(
    (config) => {
      let token = storage.getItem(TOKEN_NAME);
   
      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/"); 
      }
      return Promise.reject(error);
    }
  );
};
export default Axios;
export { attachInterceptors };
