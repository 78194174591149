import { useEffect, useState } from "react";
import {
  Tooltip,
  Button,
  Card,
  Typography,
  Input,
  Switch,
  Select,
  AutoComplete,
} from "antd";
import { DownOutlined } from "@ant-design/icons";

import {
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  FunnelPlotOutlined,
} from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import SortingArrow from "../../Common/SortingArrow";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {
  deleteOppApi,
  getOpportunityListing,
  updateOpStatusApi,
} from "../../service/opportunative.service";
import { formatDate } from "../../Utils/commonFun";
import { getSpListingApi } from "../../service/sp.service";
import { toast } from "react-toastify";
import Paginator from "../../Common/Pagination";

const { Search } = Input;

let timer = null;
let timeOut = null;

const ManageOpportunity = () => {
  const navigate = useNavigate();

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({ page: 1, limit: 10 });
  const [searchValue, setSearchValue] = useState("");
  const [searchParams] = useSearchParams();
  const [searchSp, setSearchSp] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const [spData, setSpData] = useState({});
  const [id, setId] = useState(null);
  const [spList, setSpList] = useState([]);
  const [searchSpOk, setSearchSpOk] = useState("");
  const handleDeleteOk = async () => {
    try {
      if (!id) return;
      setDeleteLoading(true);
      let res = await deleteOppApi(id);
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        // let filterData = data.data.filter((d) => d?.id !== id);
        // setData({ ...data, data: filterData });
        getList();
        setId(null);
      } else {
        toast.error(res?.data?.message);
      }
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
    setIsDeleteModalOpen(false);
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };
  const filterByStatus = [
    {
      value: "active",
      label: "Active",
    },
    {
      value: "inactive",
      label: "Inactive",
    },
  ];

  const getList = async () => {
    try {
      setDataLoading(true);
      navigate(
        `?page=${filter?.page ?? ""}&limit=${filter?.limit ?? ""}&sort_field=${
          filter?.sort_field ?? ""
        }&sort_direction=${filter?.sort_direction ?? ""}&status=${
          filter?.status ?? ""
        }&search=${filter?.search ?? ""}&sp_name=${
          filter?.sp_name ?? ""
        }&user_id=${filter?.user_id ?? ""}`
      );
      let res = await getOpportunityListing(filter);
      if (res?.status === 200) {
        setData(res?.data?.data);
      }
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    clearInterval(timer);
    timer = setTimeout(() => {
      getList();
    }, 300);
  }, [filter]);
  useEffect(() => {
    setFilter({
      limit: 10,
      page: searchParams.get("page") ?? "",
      sort_field: searchParams.get("sort_field") ?? "",
      sort_direction: searchParams.get("sort_direction") ?? "",
      status: searchParams.get("status") ? searchParams.get("status") : null,
      search: searchParams.get("search") ? searchParams.get("search") : null,
      sp_name: searchParams.get("sp_name") ? searchParams.get("sp_name") : null,
      user_id: searchParams.get("user_id") ? searchParams.get("user_id") : null,
    });
    setSearchValue(
      searchParams.get("search") ? searchParams.get("search") : null
    );
    setSpData(searchParams.get("sp_name") ? searchParams.get("sp_name") : null);
  }, []);
  const getSpList = async () => {
    try {
      let res = await getSpListingApi({ search: searchSp, limit: 500 });
      if (res.status === 200) {
        let data = res?.data?.data?.data;
        data =
          Array.isArray(data) &&
          data?.map((obj) => {
            return {
              label: obj?.sp_name,
              value: obj?.sp_name,
              id: obj?.id,
            };
          });
        setSpList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelect = (value, option) => {
    setSpData(value);
    if (option?.id || !value) {
      setFilter({
        ...filter,
        user_id: !value ? "" : option?.id,
        sp_name: value,
      });
    }
  };
  const handleSort = (sort, name) => {
    setFilter({ ...filter, sort_field: name, sort_direction: sort });
  };
  const handleStatus = async (checked, id) => {
    try {
      let changeStatus = data?.data?.map((d) => {
        if (d?.id === id) {
          d.status = checked ? "active" : "inactive";
        }
        return d;
      });
      setData({ ...data, data: changeStatus });
      let res = await updateOpStatusApi(id, {
        status: checked ? "active" : "inactive",
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        getList();
      } else {
        toast.error(res?.data?.message);
        let changeStatus = data?.data?.map((d) => {
          if (d?.id === id) {
            d.status = checked ? "inactive" : "active";
          }
          return d;
        });
        setData({ ...data, data: changeStatus });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      let changeStatus = data?.data?.map((d) => {
        if (d?.id === id) {
          d.status = checked ? "inactive" : "active";
        }
        return d;
      });
      setData({ ...data, data: changeStatus });
    }
  };
  useEffect(() => {
    clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      getSpList();
    }, 200);
  }, [searchSp]);
  return (
    <>
      <Card className="">
        <div className="flex items-center justify-between relative mb-4 flex-wrap ">
          <Typography className="text-xl font-semibold mb-0" align={"left"}>
            Manage Opportunities
          </Typography>
          <div className="flex justify-end gap-2">
            <FunnelPlotOutlined />
            <Select
              placeholder="Filter by status"
              options={filterByStatus}
              style={{
                width: 150,
              }}
              onChange={(d) => setFilter({ ...filter, status: d, page: 1 })}
              value={filter?.status}
            />
            <AutoComplete
              suffixIcon={<DownOutlined />}
              options={spList}
              style={{ width: 200 }}
              placeholder="Filter by service provider"
              value={spData}
              onChange={handleSelect} // Called when an option is selected
              onSearch={(text) => {
                if (text?.length > 2) {
                  setSearchSp(text);
                } else {
                  if (text.length < searchSpOk?.length) {
                    setSearchSp(text);
                  }
                }
                setSearchSpOk(text);
              }}
            />
            <Search
              placeholder="Search by opportunity name"
              style={{
                width: 200,
              }}
              className="cursor-pointer"
              value={searchValue}
              onChange={(e) => {
                if (e.target.value?.length > 2) {
                  setFilter({ ...filter, search: e.target.value, page: 1 });
                } else {
                  if (e.target.value.length < searchValue?.length) {
                    setFilter({ ...filter, search: e.target.value, page: 1 });
                  }
                }
                setSearchValue(e.target.value);
              }}
            />
            <Button
              type="primary"
              onClick={() => {
                if (filter?.search || filter?.status || filter?.user_id) {
                  setFilter({
                    ...filter,
                    search: null,
                    status: null,
                    page: 1,
                    user_id: "",
                    sp_name: "",
                  });
                }
                setSpData("");
                setSearchValue("");
                setSearchSp("");
                getSpList();
                setSearchSpOk("");
              }}
            >
              Clear filter
            </Button>
            <Button
              type="primary"
              onClick={() => navigate("/create-opportunity")}
              icon={<PlusOutlined />}
            >
              Create opportunity
            </Button>
          </div>
        </div>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 bg-gray-100">
              <tr>
                <th scope="col" className="px-3 py-3 whitespace-nowrap">
                  ID{" "}
                  <SortingArrow
                    onSort={handleSort}
                    name="id"
                    crrSort={filter?.sort_field}
                    sortDrr={filter?.sort_direction}
                  />
                </th>
                <th scope="col" className="px-3 py-3">
                  Opportunity name
                  <SortingArrow
                    onSort={handleSort}
                    name="opportunity_name"
                    crrSort={filter?.sort_field}
                    sortDrr={filter?.sort_direction}
                  />
                </th>
                <th scope="col" className="px-3 py-3">
                  Service Provider
                  <SortingArrow
                    onSort={handleSort}
                    name="sp_name"
                    crrSort={filter?.sort_field}
                    sortDrr={filter?.sort_direction}
                  />
                </th>
                <th scope="col" className="px-3 py-3">
                  Created date
                  <SortingArrow
                    onSort={handleSort}
                    name="created_at"
                    crrSort={filter?.sort_field}
                    sortDrr={filter?.sort_direction}
                  />
                </th>
                <th scope="col" className="px-3 py-3">
                  Status
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(data?.data) && data?.data?.length ? (
                data?.data?.map((op, index) => (
                  <tr key={index} className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                      onClick={() => navigate("/opportunity-details/" + op?.id)}
                    >
                      {op?.id}
                    </th>
                    <td
                      className="px-3 py-4 vertical-top cursor-pointer font-medium"
                      onClick={() => navigate("/opportunity-details/" + op?.id)}
                    >
                      {op?.opportunity_name}
                    </td>
                    <td className="px-3 py-4 vertical-top">
                      {op?.user?.sp_name}
                    </td>
                    <td className="px-3 py-4 vertical-top">
                      {formatDate(op?.created_at)}
                    </td>
                    <td className="px-3 py-4 vertical-top">
                      <Switch
                        checked={op?.status?.toLowerCase() === "active"}
                        onChange={(e) => handleStatus(e, op?.id)}
                      />
                    </td>
                    <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                      <Tooltip title="Edit">
                        <EditOutlined
                          onClick={() =>
                            navigate(`/edit-opportunity/${op?.id}`)
                          }
                          className="mx-1.5 cursor-pointer"
                        />
                      </Tooltip>
                      <Tooltip title="Delete">
                        <DeleteOutlined
                          onClick={() => {
                            setId(op?.id);
                            setIsDeleteModalOpen(true);
                          }}
                          className="mx-1.5 cursor-pointer"
                        />
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="no-record-found">
                  <span>{dataLoading ? "Loading..." : "No record found"}</span>
                </div>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="text-base font-semibold">
            Total opportunity- {data?.totalCount}
          </div>
          <Paginator
            defaultCurrent={1}
            current={filter.page}
            onChange={(data) => {
              setFilter({ ...filter, page: data });
            }}
            total={data?.totalCount}
          />
        
        </div>
      </Card>
      {/* delete modal */}
      <ConfirmationModal
        btnTxt={"Delete"}
        ConfirmationHeading={"Delete opportunity"}
        ConfirmationParagraph={"Do you want to delete this opportunity?"}
        loading={deleteLoading}
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};
export default ManageOpportunity;
