import { useEffect, useState } from "react";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
const SortingArrow = ({ onSort, name, crrSort = "id", sortDrr = "desc" }) => {
  const [isSortedAsc, setIsSortedAsc] = useState(true);
  const [sortDir, setSortDir] = useState("");
  const handleSort = () => {
    const newSortDirection = !isSortedAsc;
    setIsSortedAsc(newSortDirection);
    onSort(sortDir === "desc" ? "asc" : "desc", name);
  };
  useEffect(() => {
    if (!sortDrr) {
      setSortDir("desc");
    } else {
      setSortDir(sortDrr);
    }
  }, [crrSort, sortDrr]);
  
  return (
    <div onClick={(e) => handleSort(e)} className="cursor-pointer inline ml-1">
      {sortDir.toLowerCase() === "desc" ? (
        <ArrowDownOutlined />
      ) : (
        <ArrowUpOutlined />
      )}
    </div>
  );
};
export default SortingArrow;
