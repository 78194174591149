import { useEffect, useState } from "react";
import {
  Button,
  message,
  Steps,
  Card,
  Typography,
  Form,
  Input,
  Upload,
  Switch,
  Row,
  Col,
  Checkbox,
  AutoComplete,
  Image,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";

import {
  LeftOutlined,
  UploadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ImgCrop from "antd-img-crop";
import { getSpListingApi } from "../../service/sp.service";
import Editor from "./editor";
import {
  createOpportunityApi,
  getOpDetail,
  getTemplateApi,
  updateOpportunityApi,
} from "../../service/opportunative.service";
import TemplateViewSideBar from "./templateViewBar";
import { toast } from "react-toastify";
let timeOut = null;

const CreateOpportunity = () => {
  let params = useParams();
  let id = params?.id || null;
  const [fileList, setFileList] = useState([]);
  const [isImgErr, setIsImgErr] = useState(false);
  const [spData, setSpData] = useState({});
  const [spList, setSpList] = useState([]);
  const [form] = Form.useForm();
  const [isEditor, setIsEditor] = useState(false);
  const [isDrawer, setIsDrawer] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const navigate = useNavigate();
  
  const [current, setCurrent] = useState(0);
  const [searchSp, setSearchSp] = useState("");
  const [formsData, setFormsData] = useState({});
  const [content, setContent] = useState("");
  const [validation, setValidation] = useState({
    agreement: "",
  });
  const [templateList, setTemplateList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchSpOk, setSearchSpOk] = useState("");
  const [previewImage, setPreviewImage] = useState({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [is_affliate, setIs_affliate] = useState(false);
  const handleChange = (event, cont) => {
    setContent(cont);
    setIsDrawer(true);
  };
  const beforeUpload = (file) => {
    if (file.size > 10000000) {
      return Upload.LIST_IGNORE;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileList([{ url: reader.result, name: file.name, file: file }]);
      form.setFields([
        {
          name: "logo",
          errors: [],
        },
      ]);
      setIsImgErr(false);
    };
    return false;
  };
  const onChange = ({ fileList: newFileList }) => {
    form.setFieldsValue({
      image: newFileList,
    });
    form.validateFields(["logo"]);
    setFileList(newFileList);
  };
  const handleSelect = (value, option) => {
    setSpData(option);
    form.setFieldsValue({ spName: option });
  };

  const closeSideBar = () => {
    setContent({});
    setIsDrawer(false);
  };

  const onEditorChange = (event, editor) => {
    let data = editor.getData();
    form.setFieldValue("agreement", data);

    if (
      data
        ?.replace(/(&nbsp;)+/g, " ")
        ?.replace(/<[^>]*>/g, "")
        ?.trim()?.length > 5000
    ) {
      setValidation({
        agreement: "Max length 5000 char!",
      });
    } else {
      setValidation({
        agreement: "",
      });
    }
  };
  const onPreview = async (file) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewOpen(true);
  };
  const steps = [
    {
      title: "Step 1",
      content: (
        <div className="w-2/3">
          <Form layout="vertical" name="step1" form={form}>
            <Form.Item
              label="Select service provider"
              name="spName"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && typeof value === "object" && value.id) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Please select service provider!")
                    );
                  },
                },
              ]}
            >
              <AutoComplete
                suffixIcon={<DownOutlined />}
                options={spList}
                placeholder="Please select"
                disabled={id ? true : false}
                onSelect={handleSelect}
                onSearch={(text) => {
                  if (text?.length > 2) {
                    setSearchSp(text);
                  } else {
                    if (text.length < searchSpOk?.length) {
                      setSearchSp(text);
                    }
                  }
                  setSearchSpOk(text);
                }} // Update search text
              />
            </Form.Item>
            <Form.Item
              label="Opportunity name"
              name="opportunity_name"
              rules={[
                { required: true, message: "Please input opportunity name " },
                {
                  max: 35,
                  message: "Opportunity name not exceed more than 35 char.",
                },
              ]}
            >
              <Input
                size="large"
                label="Opportunity name"
                placeholder="Opportunity name"
              />
            </Form.Item>
            <Form.Item
              label="Calendly URL"
              name="event_url"
              rules={[
                {
                  type: "url",
                  message: "Please enter a valid URL",
                },
              ]}
            >
              <Input size="large" placeholder="Calendly url" />
            </Form.Item>
            <Form.Item
              label="Summary"
              name="summary"
              rules={[
                { required: true, message: "Please input summary " },
                {
                  max: 100,
                  message: "Summary not exceed more thant 100 char.",
                },
              ]}
            >
              <Input
                size="large"
                label="Summary"
                placeholder="Summary"
              />
            </Form.Item>

            <Form.Item
              label="Image"
              name="image"
              rules={[{ required: true, message: "Please select image!" }]}
            >
              <ImgCrop
                showGrid
                beforeCrop={(file) => {
                  const isJpgOrPng =
                    file.type === "image/jpeg" ||
                    file.type === "image/png" ||
                    "image/jpg";

                  if (!isJpgOrPng) {
                    message.error(`${file.name} is not a JPG/PNG file!`);
                    return false; // Ignore file and prevent upload
                  }
                  if (file.size > 10000000) {
                    message.error(`${file.name} must be smaller than 10MB!`);
                    return false;
                  }
                  return true;
                }}
                rotationSlider
                aspectSlider
                showReset
                className="w-[180px] h-[180px]"
              >
                <Upload
                  accept=".jpg, .jpeg, .png"
                  multiple={false}
                  listType="picture-card"
                  fileList={fileList}
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  onChange={onChange}
                  onPreview={onPreview}
                >
                  {!fileList?.length && (
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  )}
                </Upload>
              </ImgCrop>
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: true, message: "Please enter description!" }]}
            >
              <CKEditor
                onChange={(e, value) =>
                  form.setFieldValue("description", value.getData())
                }
                config={{
                  removePlugins: [
                    "CKFinderUploadAdapter",
                    "CKFinder",
                    "EasyImage",
                    "Image",
                    "ImageCaption",
                    "ImageStyle",
                    "ImageToolbar",
                    "ImageUpload",
                  ],
                  image: {
                    toolbar: [
                      "imageStyle:full",
                      "imageStyle:side",
                      "|",
                      "imageTextAlternative",
                    ],
                  },
                }}
                data={form.getFieldValue("description")}
                className="min-h-72"
                editor={ClassicEditor}
              />
            </Form.Item>
            <Form.Item
              label="Is profile review required?"
              name="profile_review_required"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Agreement of Opportunity"
              name="agreement"
              required={true}
              validateStatus={validation?.agreement ? "error" : ""}
              help={validation?.agreement}
            >
              <CKEditor
                className="min-h-72"
                data={form.getFieldValue("agreement")}
                config={{
                  toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                  ],
                }}
                onChange={onEditorChange}
                editor={ClassicEditor}
              />
            </Form.Item>
            <Form.Item
              label="Platform commision percentage"
              name="platform_commission"
              tooltip={{
                title: "Platform commision percentage",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your platform commision ",
                },
                {
                  validator: (_, value) => {
                    if (!value || Number(value) <= 100) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Platform commision must not exceed 100 .")
                    );
                  },
                },
              ]}
            >
              <Input
                type="number"
                size="large"
                label="USD"
                placeholder="%"
                className="no-arrows"
              />
            </Form.Item>
            <Form.Item
              label="Commission percentage (for all affiliates)"
              name="commission_percentage"
              rules={[
                {
                  required: true,
                  message: "Please enter your commission percentage",
                },
                {
                  validator: (_, value) => {
                    if (!value || Number(value) <= 100) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Commission percentage must not exceed 100 .")
                    );
                  },
                },
              ]}
            >
              <Input
                size="large"
                className="no-arrows"
                type="number"
                placeholder="%"
              />
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
      title: "Step 2",
      content: (
        <div className="w-2/3 mb-[100px] ">
          <Row gutter={15} className="text-center">
            {Array.isArray(templateList?.rows) &&
              templateList?.rows?.map((temp) => {
                return (
                  <Col className="gutter-row selectTemplate" span={3}>
                    <Checkbox
                      value="template1"
                      checked={
                        selectedTemplate.html_template === temp?.html_template
                      }
                      onChange={(event) => {
                        handleChange(event, temp);
                      }}
                    >
                      <div className="selectTemplateLable">
                        <img src="/images/template-02.png" alt="template" />
                      </div>
                      <p className="text-base pt-1 w-full break-words break-all">
                        {temp?.html_template}
                      </p>
                    </Checkbox>
                  </Col>
                );
              })}
          </Row>
        </div>
      ),
    },
  ];

  const next = async () => {
    try {
      let isError = false;
      let data = await form.getFieldsValue();
      if (
        !data?.agreement ||
        !data?.agreement
          ?.replace(/(&nbsp;)+/g, " ")
          ?.replace(/<[^>]*>/g, "")
          ?.trim()?.length > 5000
      ) {
        if (!data?.agreement) {
          setValidation({
            agreement: "Please input agreement",
          });
        }
        if (!data?.spName?.id) {
        }
        if (
          !data?.agreement
            ?.replace(/(&nbsp;)+/g, " ")
            ?.replace(/<[^>]*>/g, "")
            ?.trim()?.length > 5000
        ) {
          setValidation({
            agreement: "Max length 5000 char!",
          });
        }
        isError = true;
      }

      await form.validateFields();

      if (!isError) {
        setCurrent(current + 1);
        setFormsData(data);
      }
    } catch (error) {
      console.log("error");
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const step = [
    {
      title: <span className="text-sm">Opportunity Info</span>,
    },
    {
      title: (
        <span className="text-sm whitespace-nowrap	">Design Landing page</span>
      ),
    },
  ];
  const stepItems = step.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const getSpList = async () => {
    try {
      let res = await getSpListingApi({ search: searchSp });
      if (res.status === 200) {
        let data = res?.data?.data?.data;
        data =
          Array.isArray(data) &&
          data?.map((obj) => {
            return {
              label: obj?.sp_name,
              value: obj?.sp_name,
              id: obj?.id,
            };
          });
        setSpList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      getSpList();
    }, 200);
  }, [searchSp]);

  const getTemplateList = async () => {
    try {
      let res = await getTemplateApi();
      if (res?.status === 200) {
        setTemplateList(res?.data?.data);
      }
      return res;
    } catch (error) {
      console.log("error from template list", error);
    }
  };
  useEffect(() => {
    getTemplateList();
  }, []);
  const editTemplate = () => {
    setIsEditor(true);
  };
  const handleUse = (data) => {
    setSelectedTemplate(data);
    closeSideBar();
  };
  const handleOpp = async () => {
    try {
      if (!Object.keys(selectedTemplate).length) {
        message.error("Please select template");
        return;
      }
      setLoading(true);
      let fData;
      if (id) {
        if (is_affliate) {
          fData = await form.getFieldsValue();
        } else {
          fData = formsData;
        }
      } else {
        fData = formsData;
      }
      let formData = new FormData();
      let { spName, image, profile_review_required,event_url, ...opData } = fData;
      for (let key in opData) {
        formData.append(key, opData[key]);
      }
      if(event_url){
        formData.append("event_url", event_url);
      }else{
        formData.append("event_url", "");
      }
      if (!profile_review_required) {
        formData.append("profile_review_required", false);
      } else {
        formData.append("profile_review_required", profile_review_required);
      }
      formData.append("user_id", spData?.id);
      if (!is_affliate) {
        formData.append("template_json", selectedTemplate.json_template_data);
        formData.append("template_html", selectedTemplate.html_template_data);
      }
      fileList[0]?.file && formData.append("image", fileList[0]?.file);
      let res;
      if (id) {
        res = await updateOpportunityApi(id, formData);
      } else {
        res = await createOpportunityApi(formData);
      }
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        navigate("/manage-opportunity");
      } else {
        toast.error(res?.data?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const getData = async () => {
    try {
      let res = await getOpDetail(id);
      if (res?.status === 200) {
        let resData = res?.data?.data;
        setIs_affliate(resData?.is_affiliate);
        setSpData({
          label: resData?.user?.sp_name,
          value: resData?.user?.sp_name,
          id: resData?.user?.id,
        });
        let obj = {
          user_id: resData?.user_id,
          spName: {
            label: resData?.user?.sp_name,
            value: resData?.user?.sp_name,
            id: resData?.user?.id,
          },
          opportunity_name: resData?.opportunity_name,
          summary: resData?.summary,
          description: resData?.description,
          profile_review_required: resData?.profile_review_required,
          agreement: resData?.agreement,
          per_deal_cost: resData?.per_deal_cost,
          commission_percentage: resData?.commission_percentage,
          image: [{ url: resData?.image }],
          event_url: resData?.event_url,
          platform_commission: resData?.platform_commission,
        };

        form.setFieldsValue(obj);
        setFileList([
          {
            url: resData?.image,
          },
        ]);
        setSelectedTemplate({
          html_template_data: resData?.template_html_data,
          json_template_data: resData?.template_json_data,
          html_template: resData?.template_html,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const onTempList = () => {
    setIsEditor(false);
    setCurrent(1);
  };

  const onTemplateSave = (data) => {
    getTemplateList();
    closeSideBar();
    setIsEditor(false);
    setCurrent(1);
    setSelectedTemplate({
      html_template_data: data?.template_html_data,
      json_template_data: data?.template_json_data,
      html_template: data?.html_template,
    });
  };
  return (
    <>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: "none",
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
      {isEditor ? (
        <Editor
          editData={content}
          onTempList={onTempList}
          onSave={onTemplateSave}
        />
      ) : (
        <Card className="opportunityDetail">
          <TemplateViewSideBar
            open={isDrawer}
            content={content}
            onEdit={editTemplate}
            onClose={closeSideBar}
            handleUse={handleUse}
          />
          <div className="flex justify-between">
            <Typography className="text-xl font-semibold mb-10" align={"left"}>
              <LeftOutlined
                className="mr-2 "
                onClick={() => {
                  if (current === 1) {
                    prev();
                  } else {
                    navigate(-1);
                  }
                }}
              />
              {!id ? "Create" : " Edit"} Opportunity
            </Typography>
            {current === steps.length - 1 && (
              <Button type="primary">
                <p onClick={() => setIsEditor(true)}>
                  Create customize template
                </p>
              </Button>
            )}
          </div>
          <div className="w-2/6 mb-5">
            <Steps
              current={current}
              items={stepItems}
              labelPlacement="vertical"
            />
          </div>
          <div>{steps[current].content}</div>
          <div className="mt-6">
            {current < steps.length - 1 && !is_affliate && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {(current === steps.length - 1 || is_affliate) && (
              <Button
                type="primary"
                loading={loading}
                onClick={() => handleOpp()}
              >
                {id ? "Update" : "Create"}
              </Button>
            )}

            <Button
              className="ml-2"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </div>
        </Card>
      )}
    </>
  );
};
export default CreateOpportunity;
