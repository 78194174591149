import { useEffect, useState } from "react";
import {
  Tooltip,
  Button,
  Card,
  Typography,
  Input,
  Switch,
  Select,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  FunnelPlotOutlined,
} from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import SortingArrow from "../../Common/SortingArrow";
import FilterServiceProviders from "./FilterClientManagement";
import {
  deleteServiceProviderApi,
  getSpListingApi,
  resetPwdLinkAPi,
  updateSpStatusApi,
} from "../../service/sp.service";
import { formatDate, spStatus } from "../../Utils/commonFun";
import DeleteAccountRequest from "../../Modal/DeleteAccountRequest";
import { toast } from "react-toastify";
import Paginator from "../../Common/Pagination";

const { Search } = Input;

const filterByStatus = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
  {
    value: "pending",
    label: "Invitation pending",
  },
];
let timer = null;
const ManageServiceProviders = () => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [data, setData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState({ page: 1, limit: 10 });
  const [id, setId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  let [resetPwdLoading, setResetPwdLoading] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const handleSort = (sort, name) => {
    setFilter({ ...filter, sort_field: name, sort_direction: sort });
  };

  const handleDeleteOk = async () => {
    try {
      if (!id) return;
      setDeleteLoading(true);
      let res = await deleteServiceProviderApi(id);
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        getData();
        setId(null);
        setIsDeleteModalOpen(false);
      } else {
        toast.error(res?.data?.message);
      }
      setDeleteLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setDeleteLoading(false);
    }
  };
  
  const handleDeleteCancel = () => {
    setId(null);
    setIsDeleteModalOpen(false);
  };

  const handleFilterDrawerClose = () => {
    setFilterDrawerOpen(false);
  };
  const getData = async () => {
    try {
      setDataLoading(true);
      navigate(
        `?page=${filter?.page ?? ""}&limit=${filter?.limit ?? ""}&sort_field=${
          filter?.sort_field ?? ""
        }&sort_direction=${filter?.sort_direction ?? ""}&status=${
          filter?.status ?? ""
        }&search=${filter?.search ?? ""}`
      );
      let res = await getSpListingApi(filter);
      if (res?.status === 200) {
        setData(res?.data?.data);
      }
      setDataLoading(false);
    } catch (error) {
      console.log(error);
      setDataLoading(false);
    }
  };
  const handleStatus = async (checked, id) => {
    try {
      let changeStatus = data?.data?.map((d) => {
        if (d?.id === id) {
          d.status = checked ? "active" : "inactive";
        }
        return d;
      });
      setData({ ...data, data: changeStatus });
      let res = await updateSpStatusApi(id, {
        status: checked ? "active" : "inactive",
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        getData();
      } else {
        toast.error(res?.data?.message);
        let changeStatus = data?.data?.map((d) => {
          if (d?.id === id) {
            d.status = checked ? "inactive" : "active";
          }
          return d;
        });
        setData({ ...data, data: changeStatus });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      let changeStatus = data?.data?.map((d) => {
        if (d?.id === id) {
          d.status = checked ? "inactive" : "active";
        }
        return d;
      });
      setData({ ...data, data: changeStatus });
    }
  };
  const resetPasswordLinkHandle = async (id) => {
    try {
      setResetPwdLoading([...resetPwdLoading, id]);
      let res = await resetPwdLinkAPi(id);
      if (res?.status === 200) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
      setResetPwdLoading((prev) => prev.filter((pId) => pId !== id));
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setResetPwdLoading((prev) => prev.filter((pId) => pId !== id));
    }
  };
  useEffect(() => {
    clearInterval(timer);
    timer = setTimeout(() => {
      getData();
    }, 300);
  }, [filter]);
  
  useEffect(() => {
    setFilter({
      limit: 10,
      page: searchParams.get("page") ?? "",
      sort_field: searchParams.get("sort_field") ?? "",
      sort_direction: searchParams.get("sort_direction") ?? "",
      status: searchParams.get("status") ? searchParams.get("status") : null,
      search: searchParams.get("search") ? searchParams.get("search") : null,
    });
    setSearchValue(
      searchParams.get("search") ? searchParams.get("search") : null
    );
  }, []);
  return (
    <>
      <Card>
        <div className="flex items-center justify-between relative mb-4 flex-wrap">
          <Typography className="text-xl font-semibold mb-0" align={"left"}>
            Service Providers
          </Typography>
          <div className="flex justify-end gap-3">
            <FunnelPlotOutlined />
            <Select
              placeholder="Filter by status"
              options={filterByStatus}
              onChange={(d) => setFilter({ ...filter, status: d, page: 1 })}
              value={filter?.status}
              style={{
                width: 150,
              }}
            />
            <Search
              placeholder="Search by service provider name, email address"
              style={{
                width: 350,
              }}
              value={searchValue}
              onChange={(e) => {
                if (e.target.value?.length > 2) {
                  setFilter({ ...filter, search: e.target.value, page: 1 });
                } else {
                  if (e.target.value.length < searchValue?.length) {
                    setFilter({ ...filter, search: e.target.value, page: 1 });
                  }
                }
                setSearchValue(e.target.value);
              }}
              className="mx-1.5 cursor-pointer"
            />
            <Button
              type="primary"
              onClick={() => {
                if (filter?.search || filter?.status) {
                  setFilter({ ...filter, search: null, status: null, page: 1 });
                }
                setSearchValue("");
              }}
            >
              Clear filter
            </Button>
            <Button
              type="primary"
              onClick={() => navigate("/create-service-providers?")}
              icon={<PlusOutlined />}
            >
              Add service provider
            </Button>
          </div>
        </div>
        <div className="relative overflow-x-auto">
          <table
            dataSource={data?.data}
            className="w-full text-sm text-left text-gray-500 table-auto overflow-scroll min-w-[1140px]"
          >
            <thead className="text-xs text-gray-700 bg-gray-100">
              <tr>
                <th scope="col" className="px-3 py-3 whitespace-nowrap">
                  ID{" "}
                  <SortingArrow
                    onSort={handleSort}
                    name="id"
                    crrSort={filter?.sort_field}
                    sortDrr={filter?.sort_direction}
                  />
                </th>
                <th scope="col" className="px-3 py-3">
                  Service provider{" "}
                  <SortingArrow
                    onSort={handleSort}
                    name="sp_name"
                    crrSort={filter?.sort_field}
                    sortDrr={filter?.sort_direction}
                  />
                </th>
                <th scope="col" className="px-3 py-3">
                  Contact person name{" "}
                  <SortingArrow
                    onSort={handleSort}
                    name="first_name"
                    crrSort={filter?.sort_field}
                    sortDrr={filter?.sort_direction}
                  />
                </th>
                <th scope="col" className="px-3 py-3">
                  Email address{" "}
                  <SortingArrow
                    onSort={handleSort}
                    name="email"
                    crrSort={filter?.sort_field}
                    sortDrr={filter?.sort_direction}
                  />
                </th>
                <th scope="col" className="px-3 py-3">
                  Industry{" "}
                  <SortingArrow
                    onSort={handleSort}
                    name="industry"
                    crrSort={filter?.sort_field}
                    sortDrr={filter?.sort_direction}
                  />
                </th>
                <th scope="col" className="px-3 py-3">
                  Registered date{" "}
                  <SortingArrow
                    onSort={handleSort}
                    name="created_at"
                    crrSort={filter?.sort_field}
                    sortDrr={filter?.sort_direction}
                  />
                </th>
                <th scope="col" className="px-3 py-3">
                  Status
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(data?.data) && data?.data?.length ? (
                data?.data?.map((d) => (
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                      onClick={() =>
                        navigate(`/view-service-providers/${d?.id}`)
                      }
                    >
                      {d?.id}
                    </th>
                    <td
                      className="px-3 py-4 vertical-top cursor-pointer"
                      onClick={() =>
                        navigate(`/view-service-providers/${d?.id}`)
                      }
                    >
                      {d?.sp_name}
                    </td>
                    <td className="px-3 py-4 vertical-top">{d?.first_name}</td>
                    <td className="px-3 py-4 vertical-top">{d?.email}</td>
                    <td className="px-3 py-4 vertical-top">{d?.industry} </td>
                    <td className="px-3 py-4 vertical-top">
                      {formatDate(d?.created_at)}
                    </td>
                    <td className="px-3 py-4 vertical-top">
                      <span class="py-2 px-1.5 bg-slate-100 text-black text-sm rounded-lg whitespace-nowrap">
                        {spStatus[d?.status]}
                      </span>
                    </td>
                    <td className="px-3 py-4 text-center whitespace-nowrap vertical-top gap-2 flex justify-center ">
                      {d?.status === "pending" && (
                        <Button
                          loading={resetPwdLoading.includes(d.id)}
                          onClick={() => resetPasswordLinkHandle(d?.id)}
                        >
                          Resend password link
                        </Button>
                      )}
                      {d?.status !== "pending" && (
                        <Switch
                          size="small"
                          checked={d?.status?.toLowerCase() === "active"}
                          onChange={(e) => handleStatus(e, d?.id)}
                        />
                      )}
                      <Tooltip title="Edit">
                        <EditOutlined
                          onClick={() =>
                            navigate(`/update-service-providers/${d?.id}`)
                          }
                          className="mx-1.5 cursor-pointer"
                        />
                      </Tooltip>

                      <Tooltip title="Delete">
                        <DeleteOutlined
                          onClick={() => {
                            setId(d?.id);
                            setIsDeleteModalOpen(true);
                          }}
                          className="mx-1.5 cursor-pointer"
                        />
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <div className="no-record-found">
                  <span>{dataLoading ? "Loading..." : "No record found"}</span>
                </div>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center mt-4">
          <div className="text-base font-semibold">
            Total service provider- {data?.totalCount ?? 0}
          </div>
          <Paginator
            defaultCurrent={1}
            current={filter.page}
            onChange={(data) => {
              setFilter({ ...filter, page: data });
            }}
            total={data?.totalCount}
          />
        </div>
      </Card>
      {/* delete modal */}
      <DeleteAccountRequest
        ConfirmationHeading="Delete"
        ConfirmationParagraph="Are you sure you want to delete this Service provider?"
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        loading={deleteLoading}
        btnTxt="Delete"
      />
      <FilterServiceProviders
        isOpen={filterDrawerOpen}
        onClose={handleFilterDrawerClose}
      />
    </>
  );
};
export default ManageServiceProviders;
