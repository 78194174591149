import {
  Table,
  Button,
  Modal,
  Typography,
  Pagination,
} from "antd";
import {
  LeftOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import ViewNoteFilter from "./ViewNoteFilter";
import { getOpNotesHistory } from "../../service/opportunative.service";
import { formatDate } from "../../Utils/commonFun";
let timer = null;
const ViewNote = ({ isOpen, onBack, id }) => {
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [notesData, setNotesData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleReadMoreToggle = (record) => {
    const isExpanded = expandedRows.includes(record.id);
    if (isExpanded) {
      setExpandedRows(expandedRows.filter(key => key !== record.id));
    } else {
      setExpandedRows([...expandedRows, record.id]);
    }
  };
  const renderContentWithReadMore = (text, record) => {
    
    const isExpanded = expandedRows.includes(record.id);
    const maxChars = 60; // Limit before showing "Read More"
    const content = isExpanded ? text : text.substring(0, maxChars);

    return (
      <>
        {content}
        {text.length > maxChars && (
          <>
            {isExpanded ? ' ' : '...'}
            <Button type="link"
             onClick={() => handleReadMoreToggle(record)}
             >
              {isExpanded ? 'Show Less' : 'Read More'}
            </Button>
          </>
        )}
      </>
    );
  };
  const handleFilterDrawerClose = () => {
    setFilterDrawerOpen(false);
  };
  const getNotes = async () => {
    try {
      if (!id) {
        setIsLoading(false);
        return;
      }
      let res = await getOpNotesHistory(id, filter);

      setNotesData(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const getStage = (json, stage) => {
    let stageData = json?.find((stg) => stg?.slug === stage);
    if (stageData) {
      return stageData?.key + " " + stageData?.value;
    }
    return "-";
  };
  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      getNotes();
    }, 200);
  }, [id, filter]);

  const onSorting = (pagination, filters, sorter) => {
    const sortField = sorter.field;
    const sortOrder = sorter.order === "ascend" ? "asc" : "desc";
    setFilter({
      ...filter,
      sort_field: sortField,
      sort_direction: sortOrder,
    });
  };
  
  return (
    <>
      <Modal
        closeIcon={true}
        onClose={() => onBack(false)}
        onCancel={onBack}
        centered
        open={isOpen}
        width={1000}
        footer={false}
      >
        <div className="flex relative items-start mb-4">
          <LeftOutlined className="mr-2 mt-2" onClick={() => onBack(false)} />
          <div>
            <Typography className="text-xl font-semibold mb-1" align={"left"}>
              Notes History
            </Typography>
            <Typography className="text-sm font-semibold mb-0.5">
              {notesData?.customer_detail_json?.business_name}
            </Typography>
            <div className="flex items-center gap-4">
              <Typography className="text-sm font-normal mb-0.5">
                {formatDate(notesData?.last_updated_note_date, "dt")}
              </Typography>
              <Typography className="text-sm font-normal mb-0.5 bg-gray-200 rounded px-2 py-1">
                {getStage(notesData?.stage_timeline, notesData?.stage)}
              </Typography>
            </div>
          </div>
        </div>
        <Table
          className="notes-history-table"
          pagination={false}
          dataSource={notesData?.data}
          onChange={onSorting}
          loading={isLoading}
          columns={[
            {
              title: "Notes added on ",
              dataIndex: "created_at",
              key: "created_at",
              render: (val) => formatDate(val, "dt"),
              sorter: true,
            },
            {
              title: "Notes",
              className: "max-w-[500px] whitespace-pre-wrap	",
              dataIndex: "notes",
              key: "notes",
              sorter: true,
              render: (text, record) => renderContentWithReadMore(text, record),
            },
            {
              title: "Action Taken",
              dataIndex: "action_taken",
              key: "action_taken",
              sorter: true,
            },
            {
              title: "Communication Medium",
              dataIndex: "communication_medium",
              key: "communication_medium",
              sorter: true,
            },
          ]}
          showSorterTooltip={false}
        />
        <Pagination
          className="mt-4 justify-center mx-auto w-fit"
          defaultCurrent={1}
          total={notesData?.totalCount}
          onChange={(page, pageSize) => {
            setFilter({
              ...filter,
              page,
            });
          }}
          pageSize={filter?.limit}
          showTotal={(total) => `Total ${total} items`}
        />
      </Modal>
      <ViewNoteFilter
        isOpen={filterDrawerOpen}
        onClose={handleFilterDrawerClose}
      />
    </>
  );
};
export default ViewNote;
