import React, { useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import {
  addTemplateApi,
  updateTemplateApi,
} from "../../service/opportunative.service";
import { Button } from "antd";
import { toast } from "react-toastify";

const Editor = ({ editData, onTempList, onSave }) => {
  const [loading, setLoading] = useState(false);
  const [isEditor, setIsEditor] = useState(false);
  const emailEditorRef = useRef(null);

  const onReady = async (unlayer) => {
    setIsEditor(true);
    if (editData && Object.keys(editData).length) {
      unlayer.loadDesign(JSON.parse(editData?.json_template_data));
    }
  };
  const saveDesign = async () => {
    const unlayer = emailEditorRef.current?.editor;
    unlayer.exportHtml(async (data) => {
      const payload = { template_json: data.design, template_html: data.html };
      try {
        setLoading(true);
        let apiResData;
        if (editData && Object.keys(editData).length) {
          apiResData = await updateTemplateApi(payload, editData?.id);
        } else {
          apiResData = await addTemplateApi(payload);
        }
        if (apiResData?.status === 200) {
          onSave(apiResData?.data?.data);
          toast.success(apiResData?.data?.message);
        } else {
          toast.error(apiResData?.data?.message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message || error?.message);
        console.error("Error saving template:", error);
      }
    });
  };

  return (
    <div>
      {" "}
      <div className="border p-5 h-64	">
        <>
          <EmailEditor
            ref={emailEditorRef}
            onReady={onReady}
            options={{
              safeHtml: true,
              displayMode: "web",
              appearance: {
                theme: "dark", // Optional theme setting
                panels: {
                  tools: {
                    dock: "right",
                  },
                },
                
                contentWidth:"100%",
                content: {
                  width: "100%", // Set container width to 100%
                  backgroundColor: "#ffffff", // Optional background color
                },
              },
              tools: {
                form: {
                  usageLimit: 1,
                  properties: {
                    fields: {
                      value: [
                        {
                          name: "contact_person_name",
                          label: "Contact person name",
                          type: "text",
                          show_label: true,
                          required: true,
                          placeholder_text: "Enter contact person name",
                        },
                        {
                          name: "email",
                          label: "Email",
                          type: "email",
                          show_label: true,
                          required: true,
                          placeholder_text: "Enter email",
                        },
                        {
                          name: "business_name",
                          label: "Business name",
                          type: "text",
                          show_label: true,
                          required: true,
                          placeholder_text: "Enter business name",
                        },
                        {
                          name: "phone",
                          label: "Phone Number",
                          type: "text",
                          show_label: true,
                          required: true,
                          placeholder_text: "Enter phone number",
                        },
                      ],
                    },
                  },
                },
              },
            }}
          />

          {isEditor && (
            <Button
              className="btn btn-medium btn-info mx-2 btn-outline-white"
              onClick={saveDesign}
              loading={loading}
            >
              Save Design
            </Button>
          )}
          <Button
            className="btn btn-medium btn-warning mx-2 btn-outline-white"
            onClick={onTempList}
          >
            Template List
          </Button>
        </>
      </div>
    </div>
  );
};

export default Editor;
