import React, { useEffect, useState } from "react";
import { Table, Select, Button, Tooltip } from "antd";
import { FilterOutlined, ExportOutlined } from "@ant-design/icons";
import { formatDate, removeUnderScore } from "../../Utils/commonFun";
import { useNavigate } from "react-router-dom";
import ViewNote from "../../Modal/ViewNote";
import { getAffListAssOpApi } from "../../service/opportunative.service";
import Paginator from "../../Common/Pagination";
let timer = null;
const BussinessDataTable = (props) => {

  let { columns, data, stages, setFilter, filter, type, id, exportCsv } = props;

  const [affiliateList, setAffiliateList] = useState([]);
  const [searchAff, setSearchAff] = useState("");
  const [searchAffVal, setSearchAffVal] = useState("");
  const [allColumns, setAllColumns] = useState([]);
  const [allData, setAllData] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [condCol, setCondCol] = useState([]);
  const [openViewNote, setOpenViewNote] = useState(false);
  const navigate = useNavigate();
  const [viewId, setViewId] = useState(null);

  useEffect(() => {
    let dataC = columns.map((item) => {
      return {
        title: removeUnderScore(item),
        dataIndex: item,
        key: item,
      };
    });
    let dataD = data?.data?.map((item) => {
      let { customer_detail_json, ...rest } = item;
      return {
        ...customer_detail_json,
        ...rest,
      };
    });
    setAllData(dataD);
    setAllColumns(dataC);
  }, [columns, data]);

  const getAffData = async () => {
    try {
      let res = await getAffListAssOpApi(id, {
        search: searchAff,
        limit: 1000,
      });

      if (res?.status === 200) {
        let data = res?.data?.data?.data;

        let modifyData = data?.map((item) => {
          return {
            label: item?.user?.first_name + " " + item?.user?.last_name,
            value: item?.user?.id,
          };
        });
        setAffiliateList(modifyData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      getAffData();
    }, 300);
  }, [searchAff]);

  useEffect(() => {
    let sList = [];
    stages?.forEach((item) => {
      sList.push({
        label: item?.key + " " + item?.value,
        value: item?.slug,
      });
    });
    setStageList(sList);
  }, [stages]);

  useEffect(() => {
    if (type === "deals") {
      setCondCol([
        {
          title: "Total deal amount",
          key: "total_deal_amount",
          dataIndex: "total_deal_amount",
          render: (text) => <p>{"$" + text}</p>,
        },
        {
          title: "Platform commission (%)",
          dataIndex: "platform_commission_per",
          key: "platform_commission_per",
          render: (text) => <p>{text + "%"}</p>,
        },
        {
          title: "Platform commission ($)",
          dataIndex: "platform_commission_amount",
          key: "platform_commission_amount",
          render: (text) => <p>{"$" + text}</p>,
        },
        {
          title: "Affiliate commission (%)",
          dataIndex: "affiliate_commission_per",
          key: "affiliate_commission_per",
          render: (text) => <p>{text + "%"}</p>,
        },
        {
          title: "Affiliate commission ($)",
          dataIndex: "affiliate_commission_amount",
          key: "affiliate_commission_amount",
          render: (text) => <p>{"$" + text}</p>,
        },
      ]);
    } else if (type === "leads") {
      setCondCol([
        {
          title: "Potential Commission",
          dataIndex: "potential_commission_per",
          key: "potential_commission_per",
          render: (text) => <p>{text + "%"}</p>,
        },
      ]);
    }
  }, [type]);

  return (
    <div>
      <div className="flex justify-end mb-4 gap-x-2.5	">
        <FilterOutlined className="" />
        <Select
          maxTagCount="responsive"
          showSearch
          placeholder="Filter by affiliate name "
          options={affiliateList}
          style={{
            width: "100%",
          }}
          notFoundContent="No data found"
          mode="multiple"
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip
              overlayStyle={{
                pointerEvents: "none",
              }}
              title={omittedValues.map(({ label }) => label).join(", ")}
            >
              <span>More...</span>
            </Tooltip>
          )}
          filterOption={false}
          value={filter.user_id}
          onChange={(e) => {
            if (!e.length) {
              setSearchAffVal("");
              setSearchAff("");
            }
            setFilter((prev) => {
              return {
                ...prev,
                user_id: e,
                page: 1,
              };
            });
          }}
          onSearch={(e) => {
            if (e.length > 2 || e.length < searchAffVal.length) {
              setSearchAff(e);
            }
            setSearchAffVal(e);
          }}
        />
        <Select
          placeholder="Filter by status"
          maxTagCount="responsive"
          options={stageList}
          style={{
            maxWidth: "100%",
            width: "100%",
          }}
          showSearch={false}
          mode="multiple"
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip
              overlayStyle={{
                pointerEvents: "none",
              }}
              title={omittedValues.map(({ label }) => label).join(", ")}
            >
              <span>More...</span>
            </Tooltip>
          )}
          value={filter.stage}
          onChange={(e) => {
            setFilter((prev) => {
              return {
                ...prev,
                stage: e,
                page: 1,
              };
            });
          }}
        />
        <Button
          type="primary"
          onClick={() => setFilter({ page: 1, user_id: [], stage: [] })}
        >
          Clear filter
        </Button>
        <Button type="primary" onClick={exportCsv} icon={<ExportOutlined />}>
          Export
        </Button>
      </div>
      <div className="w-full overflow-x-auto overflow-y-hidden">
        <Table
          className="table-auto  min-w-[1140px]"
          columns={[
            {
              title: "Affiliate",
              dataIndex: "user",
              key: "first_name",
              render: (user) => (
                <p onClick={() => navigate("/view-affiliates/" + user?.id)}>
                  {user?.first_name + " " + user?.last_name}
                </p>
              ),
            },
            {
              title: "Affiliate email",
              dataIndex: "user",
              key: "email",
              render: (user) => <p>{user?.email}</p>,
            },
            {
              title: "Affiliate status",
              dataIndex: "user",
              key: "status",
              render: (user) => user?.status,
            },

            {
              title: "Source",
              dataIndex: "source",
              key: "source",
            },
            ...allColumns,
            {
              title: "Status & timeline",
              dataIndex: "stage",
              key: "stage",
              render: (_, data) => {
                let stg = data?.stage_timeline?.find(
                  (val) => val?.slug === data?.stage
                );
                return stg ? stg?.key + " " + stg?.value : "-";
              },
            },
            {
              title: "Last updated date & time",
              key: "statusStage",
              render: (_, data) => {
                return formatDate(data?.last_updated_date, "dt");
              },
            },
            ...condCol,
            {
              title: "Action",
              render: (user) => (
                <Button
                  type="link"
                  className="px-0"
                  onClick={() => {
                    setOpenViewNote(true);
                    setViewId(user?.id);
                  }}
                >
                  View Note
                </Button>
              ),
            },
          ]}
          dataSource={[...allData]}
          pagination={false}
        />
      </div>
      <div className="flex justify-end mt-2">
        <Paginator
          total={data?.totalCount}
          current={filter?.page}
          pageSize={10}
          onChange={(page) => setFilter({ ...filter, page: page })}
        />
      </div>
      <div className="text-base font-semibold">
        Total :- {data?.totalCount ? data?.totalCount : 0}
      </div>
      {openViewNote && (
        <ViewNote
          isOpen={openViewNote}
          onBack={() => {
            setOpenViewNote(false);
            setViewId(null);
          }}
          id={viewId}
        />
      )}
    </div>
  );
};

export default BussinessDataTable;
