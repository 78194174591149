import React from "react";
import { Pagination } from "antd";

const Paginator = ({ current, onChange, total, ...props }) => {
  return (
    <>
      {total > 10 ? (
        <Pagination
          defaultCurrent={1}
          current={current}
          onChange={onChange}
          total={total}
          {...props}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Paginator;
